import React from "react";

const Link: React.FC = () => {
  return (
    <div className="page">
      <div className="sentence">
        <div className="space" />
        <div className="space" />
        <div className="title">
          <h1>Link</h1>
        </div>
        <div className="main">
          <h1>X</h1>
          <a
            href="https://x.com/361do_sleep"
            target="_blank"
            rel="noopener noreferrer"
          >
            @361do_sleep
          </a>
          <br />
          <p>プログラミング系</p>
          <a
            href="https://x.com/361do_design"
            target="_blank"
            rel="noopener noreferrer"
          >
            @361do_design
          </a>
          <p>映像</p>
          <br />
          <br />
          <h1>Github</h1>
          <a
            href="https://github.com/rebuildup"
            target="_blank"
            rel="noopener noreferrer"
          >
            rebuildup
          </a>
          <p>頑張ってリポジトリを増やしたい</p>
          <br />
          <br />
          <h1>Booth</h1>
          <a
            href="https://361do.booth.pm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Booth
          </a>
          <p>現在は作成したAeの拡張機能を公開しています</p>
          <p>Aulymoが好評です</p>
          <br />
          <br />
          <h1>Youtube</h1>
          <a
            href="https://youtube.com/@361do_sleep?si=nBn400E7HGFYMPFI"
            target="_blank"
            rel="noopener noreferrer"
          >
            Youtube
          </a>
          <p>Ae拡張機能の動作説明など公開しています</p>
        </div>
      </div>
    </div>
  );
};

export default Link;
